import { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import { AppLoader } from './components/appLoader/AppLoader';

const AppModules = lazy(() => import('./app'));

const App = () => (
  <Suspense fallback={<AppLoader />}>
    <AppModules />
  </Suspense>
);

const container = document.getElementById(process.env['REACT_APP_CONTAINER'] ?? 'root');
if (container) {
  const root = createRoot(container);
  root.render(<App />);
} else {
  throw new Error('No provided app container');
}
